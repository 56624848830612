import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Home_Yale from "./containers/Home.yale";
// import Notes from "./containers/Notes";
import Login from "./containers/Login";
import Signup from "./containers/SignupBS";

import Details from "./containers/Details";
import Samples from "./containers/Samples";
import Done from "./containers/Done";
import Test from "./containers/Test";
import NotFound from "./containers/NotFound";

import LITWelcome from './containers/LITWelcome';
import LITSignup from "./containers/LITSignup";
import LITDetails from "./containers/LITDetails";
import LITDone from "./containers/LITDone";

import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import { useGAPageView } from './hooks/useGATracker';

// PEKNOTE: TODO: check it out - using vars below /:id

export default ({ childProps }) => {
  useGAPageView();

  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} props={childProps} />

	  <Route path="/welcome-yale" exact> <Redirect to="/welcome" /> </Route>
	  <Route path="/yale" exact> <Redirect to="/lit" /> </Route>

	  {/* These were formerly the Yale single-college routes. Comment back in to bring them back.
      <AppliedRoute path="/welcome-yale" exact component={Home_Yale} props={childProps} />
      <UnauthenticatedRoute path="/yale" exact component={Signup} props={childProps} />
	  */}

      <AppliedRoute path="/welcome" exact component={LITWelcome} props={childProps} />
      <UnauthenticatedRoute path="/lit" exact component={LITSignup} props={childProps} />
      <AuthenticatedRoute path="/litdetails" exact component={LITDetails} props={childProps} />
      <AuthenticatedRoute path="/litdone" exact component={LITDone} props={childProps} />

      <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
      {/* <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} /> */}
      <AuthenticatedRoute path="/samples" exact component={Samples} props={childProps} />
      <AuthenticatedRoute path="/details" exact component={Details} props={childProps} />
      <AuthenticatedRoute path="/done" exact component={Done} props={childProps} />
      <AuthenticatedRoute path="/test" exact component={Test} props={childProps} />
      {/* Examples:
      <AuthenticatedRoute path="/notes/new" exact component={NewNote} props={childProps} />
      <AuthenticatedRoute path="/notes/:id" exact component={Notes} props={childProps} /> 
      */}

      { /* Finally, catch all unmatched routes */ }
      <Route component={NotFound} />
    </Switch>
  )
}
