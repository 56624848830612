import React from "react"
import {
    HelpBlock,
    FormGroup,
    ControlLabel,
    Radio,
} from "react-bootstrap"
import { SafeNewTabLink } from '../components/Utility'
import _ from "lodash"
import "./Details.css"

const RatingQuestion = (props) => {
    return (
        <FormGroup controlId={props.id}>
            <ControlLabel>{props.question}</ControlLabel>
            <div className="rating-question">
                <Radio name={props.id+'_group'} value={1} className="radios" inline onChange={props.onChange}>1</Radio>{' '}
                <Radio name={props.id+'_group'} value={2} className="radios" inline onChange={props.onChange}>2</Radio>{' '}
                <Radio name={props.id+'_group'} value={3} className="radios" inline onChange={props.onChange}>3</Radio>{' '}
                <Radio name={props.id+'_group'} value={4} className="radios" inline onChange={props.onChange}>4</Radio>{' '}
                <Radio name={props.id+'_group'} value={5} className="radios" inline onChange={props.onChange}>5</Radio>{' '}
            </div>
            <HelpBlock className="spread"><span>1 = strongly disagree</span><span>5 = strongly agree</span></HelpBlock>
        </FormGroup>
    )
}

const MultipleChoiceQuestion = (props) => {
    return (
        <FormGroup controlId={props.id} onChange={props.onChange}>
            <ControlLabel>{props.question}</ControlLabel>
            <div>
                {_.map(props.choices, (choice, idx) => 
                    <Radio key={idx} name={props.id+'_group'} value={choice} className="radios">{choice}</Radio>
                )}{' '}
            </div>
        </FormGroup>
    )
}

// TODO: can just make this render(), there's no switching on conditions? perhaps?
// TODO" add onChange from props
export default function renderSurvey(onChangeHandler) {
    // PEKNOTE: don't do autofocus! Pops keyboard on mobile!

    return (
        <React.Fragment>
            <HelpBlock>Please answer the following questions so we can tailor this program and make it as useful as possible:</HelpBlock>
            <RatingQuestion id='Q_managing_money' onChange={onChangeHandler}
                question="I feel confident managing my money." 
                />
            <RatingQuestion id='Q_plan_finances' onChange={onChangeHandler}
                question="I have a plan for handling my finances." 
                />
            <RatingQuestion id='Q_money_stress' onChange={onChangeHandler}
                question="Money stresses me out." 
                />
            <RatingQuestion id='Q_studen_loans' onChange={onChangeHandler}
                question="I know how much I do / will owe in student loans." 
                />
            <RatingQuestion id='Q_talk_with_family' onChange={onChangeHandler}
                question="I feel comfortable talking to my family about money." 
                />
            <RatingQuestion id='Q_talk_with_classmates' onChange={onChangeHandler}
                question="I feel comfortable talking to my classmates about money." 
                />
            <RatingQuestion id='Q_unexpected_expense' onChange={onChangeHandler}
                question="I feel confident that I could cover a $400 unexpected expense (medical bill, plane ticket, car repair, lost/stolen/broken item)." 
                />
            
            <hr/>
            <div className="page-title-widget"><h4 className="intro"><em>Just for fun</em></h4></div>
            <HelpBlock>Let’s see how much you know already! (Source: <SafeNewTabLink href="http://www.nber.org/papers/w17107">Financial Literacy around the World: An Overview</SafeNewTabLink> by Annamaria Lusardi and Olivia S. Mitchell):</HelpBlock>
            <MultipleChoiceQuestion id='P_interest_rate' onChange={onChangeHandler}
                question="Suppose you had $100 in a savings account and the interest rate was 2% per year. After 5 years, how much do you think you would have in the account if you left the money to grow?"
                choices={ [ 'More than $102', 'Exactly $102', 'Less than $102', 'Do not know'] }/>
            <MultipleChoiceQuestion id='P_inflation' onChange={onChangeHandler}
                question="Imagine that the interest rate on your savings account was 1% per year and inflation was 2% per year. After 1 year, how much would you be able to buy with the money in this account?"
                choices={ [ 'More than today', 'Exactly the same', 'Less than today', 'Do not know'] }/>
            <MultipleChoiceQuestion id='P_buying_stock' onChange={onChangeHandler}
                question="Please tell me whether this statement is true or false. “Buying a single company’s stock usually provides a safer return than a stock mutual fund."
                choices={ [ 'True', 'False', 'Do not know'] }/>

            <div className="page-title-widget">
                <h4 className="intro"><em>Hit SUBMIT to get access to LIT!</em></h4>
            </div>
        </React.Fragment>
    )
}
