// This is same as App.js but with react-bootstrap, auth, cleanups
// and begins support for generic colleges

import React, { Component, Fragment } from "react";
import { Auth } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import Routes from "./Routes";
import "./AppBS.css";

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';

// import _ from 'lodash'

Amplify.configure(aws_exports);

class AppBS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      savedPassword: null,
      showNavbar: false,
    };
  }

  async componentDidMount() {
    try {
      // Check to see if user is logged in.
      //   console.log("AUTH", "checking session!!")
      if (await Auth.currentSession()) {
        // console.log("AUTH", "userHasAuthenticated(true)")
        await this.userHasAuthenticated(true);
      }
      // If this throws an error, try to handle below

      // const val = Cookies.get('navbar')
      // const urlArg = 'foobar'
      // See UnauthenticatedRoute
      //  const redirect = querystring("redirect");
      // const showNavbar = true
      // this.setState({ showNavbar: showNavbar })
    }
    catch(e) {
      if (e.code && e.code === "UserNotFoundException") {
        console.log("AUTH ERROR:", e.message, "Note: user was deleted perhaps.", e)
      }
      else if (e !== 'No current user') {
        // alert(e);
        console.log("AUTH ERROR:", e.message, e)
      }
      await this.userHasAuthenticated(false)
    }

    this.setState({ isAuthenticating: false });
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
        this.setState(state, resolve)
    });
  }

  userHasAuthenticated = async authenticated => {
    this.setStateAsync({ isAuthenticated: authenticated });
  }

  rememberPassword = async password => {
    sessionStorage.setItem('savedPassword', password)
    this.setStateAsync({ savedPassword: password })
  }

  getPassword = () => {
    return sessionStorage.getItem('savedPassword')
    // return this.state.savedPassword
  }

  clearPassword = () => {
    sessionStorage.removeItem('savedPassword')
  }

  handleLogout = async event => {
    await Auth.signOut();

    await this.userHasAuthenticated(false);

    this.props.history.push("/login");
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated, // value
      userHasAuthenticated: this.userHasAuthenticated, // fn
      rememberPassword: this.rememberPassword, // fn
      getPassword: this.getPassword, // fn
      clearPassword: this.clearPassword, // fn
    };

	return (
      !this.state.isAuthenticating &&
      <div className="App container">
        { this.state.showNavbar && (<Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">fintrinsic</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              {this.state.isAuthenticated
                ? <Fragment>
                    <NavItem onClick={this.handleLogout}>Logout</NavItem>
                  </Fragment>
                : <Fragment>
                    {/* <LinkContainer to="/welcome">
                      <NavItem>Welcome</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/details">
                      <NavItem>Details</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/samples">
                      <NavItem>Samples</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/done">
                      <NavItem>Done</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <NavItem>Login</NavItem>
                    </LinkContainer> */}
                  </Fragment>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar> )}
        <Routes childProps={childProps} />
      </div>
    );
  }
}

export default withRouter(AppBS);
