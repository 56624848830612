import React, { Component } from "react";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel,
  Alert,
  Image,
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Signup.css";

export default class Samples extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null,
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    // Do work....

    this.setState({ isLoading: false });
  }


  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationSubmit}>
        <Alert>Yikes!</Alert>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    );
  }

  renderForm() {
    // PEKNOTE: don't do autofocus! Pops keyboard on mobile!
    return (
      <form onSubmit={this.handleSubmit}>
        {/* TODO: center logo*/}
        <div>Logos:</div>
        <Image alt="Fintrinsic Logo" src="/FintrinsicLogo.png" />
        <div>Alerts:</div>
        <Alert bsStyle="info">Info!</Alert>
        <Alert bsStyle="success">Success!</Alert>
        <Alert bsStyle="warning">Warning!</Alert>
        <Alert bsStyle="danger">Danger!</Alert>

        <div>Inputs:</div>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <FormGroup controlId="email2" bsSize="large">
          {/* <ControlLabel>Email</ControlLabel> */}
          <FormControl
            type="email"
            // value={this.state.email}
            placeholder="Email"
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          {/* <ControlLabel>Password</ControlLabel> */}
          <FormControl
            // value={this.state.password}
            onChange={this.handleChange}
            placeholder="Password"
            type="password"
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            type="password"
            placeholder="Confirm your password"
            value={this.state.confirmPassword}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            type="tel"
            value={this.state.confirmationCode}
            placeholder="Enter the code you were emailed"
            onChange={this.handleChange}
          />
          <HelpBlock>Please check your email for the code.</HelpBlock>
        </FormGroup>
        <LoaderButton
          bsStyle="success"
          block
          bsSize="large"
          disabled={false}
          type="submit"
          isLoading={this.state.isLoading}
          text="Do Something"
          loadingText="Doing Something…"
        />
      </form>
    );
  }

  render() {
    return (
      <div className="Signup"> 
          {this.renderForm()}
      </div>
    );
  }
}
