import React from 'react'
import styled from 'styled-components'

import { useCheckboxInput } from 'react-google-forms-hooks'

import C from './common'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: baseline;

  & label {
    margin: 4px 10px;
	font-size: ${C.questionTextFontSize};
	font-weight: normal;
	max-width: 88%;
  }
`

const ErrorLabel = styled.span`
  color: red;
  padding: 10px 0;
`

export default function CheckboxInput({ id }) {
  const { options, customOption, error } = useCheckboxInput(id)

  return (
    <Container>
      {options.map((o) => (
        <CheckboxContainer key={o.id}>
          <input type='checkbox' id={o.id} {...o.registerOption()} />
          <label htmlFor={o.id}>{o.label}</label>
        </CheckboxContainer>
      ))}
      {customOption && (
        <CheckboxContainer>
          <input
            type='checkbox'
            id={customOption.id}
            {...customOption.registerOption()}
          />
          <label htmlFor={customOption.id}>Other</label>
          <input
            type='text'
            placeholder=''
            {...customOption.registerCustomInput()}
          />
        </CheckboxContainer>
      )}
      <ErrorLabel>{error && 'This field is required'}</ErrorLabel>
    </Container>
  )
}
