import React, { Component } from "react";
import { Auth } from "aws-amplify";
// import _ from "lodash";

import { enrollNewUser } from '../components/Utility'
import "./Details.css";

import { GoogleFormProvider, useGoogleForm } from 'react-google-forms-hooks'
// import { LinearGrid } from '../components/GForm'
// import LinearGrid from '../components/GForm/LinearGrid'
import LinearGrid from '../components/GForm/LinearGridBS'

// import formReisUP from '../form-reisup.json'
// import formTest from '../form-test.json'
// import formShort from '../form-short.json'

import Sample from '../components/GForm/example/App'

const Constants = {
	module: 'LITDetails',
	formReisUP: 'https://docs.google.com/forms/d/e/1FAIpQLSffWQVBY6UeXTaZT3dXr1b8vGBIlHsXWc4lGSYu0Hcm_3r31g/viewform',
	formTest: 'https://docs.google.com/forms/d/e/1FAIpQLSe5U3qvg8WHs4nkU-e6h2RlAD7fKoCkou6HO2w2-tXYIA_F8g/viewform',
	formShort: 'https://docs.google.com/forms/d/e/1FAIpQLSe5U3qvg8WHs4nkU-e6h2RlAD7fKoCkou6HO2w2-tXYIA_F8g/viewform',

	courseID: 1469681, // Omni course
}

// Logging
const log = (...args) => console.log(Constants.module, ...args)
// eslint disable-next-line
const error = (...args) => console.error(Constants.module, ...args)
// eslint disable-next-line
const mute = (...args) => null

// https://github.com/francisconeves97/react-google-forms-hooks/blob/master/example/src/App.js

const Form = ({ children }) => (<form className="Form">{children}</form>)
const QuestionContainer = ({ id, children }) => (<div id={id} className="QuestionContainer">{children}</div>)
const QuestionLabel = ({ children }) => (<h3 className="QuestionLabel">{children}</h3>)

const CommonQuestion = ({ label, type, id }) => (
	<QuestionContainer id={id}>
		<QuestionLabel id={`label-${id}`}>{label}</QuestionLabel>
		{/* <span id={`label-${id}`}>{label}</span> */}
		<div id={`id-${id}`}>{id}</div>
		<div id={`type-${id}`}>{type}</div>
	</QuestionContainer>
)

const Questions = ({ form }) => {
	return (
		<div>
			{form.fields.map((field) => {
				const { id, label, type } = field
				const key = id || (1000 * Math.random())
				let elem = null
				switch (type) {
					case 'LINEAR':
						elem = <LinearGrid id={id} />
						break;
					case undefined:
						console.log('not allowed')
						break;
					default:
						elem = <CommonQuestion id={id} label={label} type={type} />
				}

				return (
					<QuestionContainer key={key} id={id}>
						{elem}
						{/* <CommonQuestion id={id} label={label} type={type} /> */}
					</QuestionContainer>
				)
			})}
		</div>
	)
}

// SHORT_ANSWER
// RADIO
// LINEAR
// CHECKBOX

// eslint-disable-next-line no-unused-vars
const RegistrationForm = ({ form }) => {
	const methods = useGoogleForm({ form })
	const onSubmit = async (data) => {
		log('Submitting', data)
		await methods.submitToGoogleForms(data)
	}

	return (
		<GoogleFormProvider {...methods}>
			<Form onSubmit={methods.handleSubmit(onSubmit)}>
				{/* <ShortAnswerInput id='1864908950' /> */}
				{/* <ShortAnswerInput id='566341438' /> */}
				{/* <CommonQuestion id='' /> */}
				<Questions form={form} />
				<button type='submit'>Submit</button>
			</Form>
		</GoogleFormProvider>
	)
}

export default class Details extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// WARNING: if we add more non-profile state, then adjust _.omit call below.
			isLoading: false,
			isSurvey: false,
			newUser: null,
			alertMessage: "",
			bsSize: "", // "small", "", "large"

			// Profile from here down
			email: "",
			password: "",
			first_name: "",
			last_name: "",
			internal_name: "",
			school: "",
			userId: "",

			affiliation: null,
			residentialCollege: null,
			source: null,
			classYear: null,
			social: null,

			reisup_terms: false,
			teachable_terms: false,
		};
	}

	async componentDidMount() {
		if (!this.props.isAuthenticated) {
			log('componentDidMount', "isAuth", this.props.isAuthenticated)
			return
		}

		mute('componentDidMount', {
			history: !!this.props.history,
			isAuth: !!this.props.isAuthenticated,
		})

		try {
			const user = await Auth.currentUserInfo()
			mute('componentDidMount', 'currentUserInfo', !!user, user)

			if (!!user && !!user.attributes) {
				// const { email, email_verified, family_name, given_name, name, website, sub } = payload
				const { email, family_name, given_name, name, website, sub } = user.attributes || {}

				this.setState({
					email: email, first_name: given_name, last_name: family_name,
					internal_name: name, school: website, userId: sub
				})
			}
		}
		catch (err) {
			log('ERROR:', err)
		}
	}

	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

	showAlert(msg) {
		this.setStateAsync({ alertMessage: msg })
			.then(window.scrollTo(0, 100))
	}

	hideAlert() {
		this.setState({ alertMessage: "" })
	}

	async doEnrollment() {
		try {
			const { email, password = '', first_name, last_name } = this.state
			log('Enrolling user')

			const response = await enrollNewUser(Constants.courseID, email, password, `${first_name} ${last_name}`)
			let data = await response.json()
			log("enrollNewUser() response.json()", data)

			if (data.status === 'success') {
				this.setState({ didEnroll: true })

				// Skip this in the new flow, this info is going via Google Form
				// const profile = _.omit(this.state, ['isLoading', 'isSurvey', 'newUser', 'alertMessage', 'bsSize'])
				// const resp2 = await sendProfile(email, userId, profile)
				// let data2 = await resp2.json()
				// log('sendProfile() resp2.json()', data2)
			}
			else {
				throw new Error({ success: false, message: "Enrollment failed" })
			}
		}
		catch (err) {
			error("Details", "doEnrollment()", '', err)
			this.setState({ didEnroll: false })
			throw new Error({ success: false, message: err['message'] || err })
		}
	}

	handleAfterSurveySubmitted = async (data) => {
		log('handleAfterSurveySubmitted', 'starting...')
		await this.setStateAsync({ isLoading: true });

		try {
			// Do work....
			// Gather survey stuff
			log('handleAfterSurveySubmitted', 'data', data)

			// Enrolling will get its own things.
			await this.doEnrollment()

			// Redirect to welcome message
			this.props.history.push("/litdone");
		}
		catch (err) {
			log('handleAfterSurveySubmitted', 'ERROR:', err && err.message)
			this.showAlert(err['message'] || err)
		}
		finally {
			// Don't do this if we're doing a nav above (e.g. 'history.push()')
			// await this.setStateAsync({ isLoading: false });
		}
	}

	render() {
		return (
			// Note: Adjustments for this instance of details
			<div className="Details"
				style={{ backgroundColor: '#495A5826', marginTop: -15, marginRight: -15, marginLeft: -15 }}>
				{/* <RegistrationForm form={formReisUP}/> */}
				<Sample email={this.state.email} onSubmit={this.handleAfterSurveySubmitted} />
			</div>
		);
	}
}
