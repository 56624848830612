import React from "react";
import {
    FormGroup,
} from "react-bootstrap";

import colleges from '../colleges.json';

const FormGroupFix = (props) => {
    const { bsSize, ...rest } = props
    return (
        !!bsSize ? <FormGroup bsSize={bsSize} {...rest}>{props.children}</FormGroup>
            : <FormGroup {...rest}>{props.children}</FormGroup>
    )
}

const SafeNewTabLink = (props) => {
    const { href } = props

    return (
        <a target="_blank" rel="noopener noreferrer" href={href}>{props.children}</a>
    )
}

async function enrollNewUser(courseId, email, password, name) {
    return fetch('https://hooks.zapier.com/hooks/catch/3661391/gpdhof/', {
        method: 'POST',
        headers: {
            // 'Accept': 'application/json',
            // 'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "courseid": courseId,
            "email": email,
            "password": password,
            "name": name,
        }),
    });
}

async function sendProfile(email, userId, data) {
    return fetch('https://hooks.zapier.com/hooks/catch/3661391/qpjjmz/', {
        method: 'POST',
        headers: {
            // 'Accept': 'application/json',
            // 'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
}

// Return array [ account, domain ] from valid email address
function getAccountDomainFromEmail(email) {
	if (!email || 'string' !== typeof email)
		return []

    email = email.toLocaleLowerCase()
    const parts = email.split('@')

	// If missing '@', invalid but return partial
    if (1 >= parts.length)
      return parts

    // Too many '@'s, invalid
    if (2 < parts.length)
      return []

	// If here, email 'x@y...' is only two parts which is correct
	return parts
}

// Validate the domain. If invalid, double check full email address
// TODO may need to be async when calling remote API
function isApprovedEmailAddress(email) {
	if (!colleges)
		return false

    // If here, email 'x@y...' is only two parts which is correct
	const [ account, domain ] = getAccountDomainFromEmail(email)
	// One more check....
	if (!account || !domain)
		return false;

	// If domain exists, valid and done.
	if (colleges[domain]) {
		return true;
	}

	// TODO check against list of approved users.
	// TODO: check for only plus addressing and prevent '.someoneelse@gmail.com'
	if (account.startsWith('pkreipke+') && domain === 'gmail.com') {
		return true;
	}

	if (domain === 'gofintrinsic.com') {
		return true;
	}

	return false;
}

export {
    FormGroupFix,
    SafeNewTabLink,
    enrollNewUser,
    sendProfile,
	isApprovedEmailAddress,
	getAccountDomainFromEmail,
}
