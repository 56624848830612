import React, { Component } from "react";
import { LogoCopyrightFooter } from "../components/ScreenWidgets";
import "./Home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.setState({ isLoading: false });
      return;
    }

    this.setState({ isLoading: false });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Welcome to LIT by Fintrinsic</h1>
        <LogoCopyrightFooter noschool />
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.renderLander()}
      </div>
    );
  }
}
