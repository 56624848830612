import React, { Component } from "react";
import {
  HelpBlock,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import { Auth } from "aws-amplify";
import ReactGA from 'react-ga';

import LoaderButton from "../components/LoaderButton";
import { PageTitleWidget, LogoCopyrightFooter, AlertMsg } from "../components/ScreenWidgets";
import { FormGroupFix, isApprovedEmailAddress, getAccountDomainFromEmail } from '../components/Utility'
import "./Signup.css";


// Logging
const log = (...args) => console.log('LITSignup', ...args)
// eslint disable-next-line
const error = (...args) => console.error('LITSignup', ...args)
// eslint disable-next-line
const mute = (...args) => null

export default class LITSignup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      email: "",
      password: "Hidden21",
      confirmPassword: "Hidden21",
      firstName: "",
      lastName: "",
      
	  newUser: null,
	  verify: null,
      
      verificationCode: "",
      isVerified: false,

      alertMessage: "",

      bsSize: "", // "small", "", "large"
    };
  }

  componentDidMount() {
    log('componentDidMount', { history: !!this.props.history} )
    // log('componentDidMount', this.props, !!this.props.history, !!this.props.location, !!this.props.match)
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
        this.setState(state, resolve)
    });
  }

  showAlert(msg) {
    this.setStateAsync({ alertMessage: msg })
    .then(window.scrollTo(0,100))
  }

  hideAlert() {
    this.setState({ alertMessage: "" })
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleToggleVerification = (event) => {
    event.preventDefault();
	const { verify } = this.state
	const next = verify ? null : true
	console.log('Toggle Verification state from', verify, 'to', next)
	this.setState({
		verify: next,
		alertMessage: "", // same as hideAlert()
	})

	const dest = next ? '/lit/verify' : '/lit/back'
	console.log('Record view', dest)
	ReactGA.modalview(dest)
  }

  //*************** VERIFICATION

  validateVerificationForm() {
    // TODO: check for all digits, or does 'type="tel"' do that?
    return this.state.verificationCode.length > 0;
  }

  handleVerificationSubmit = async event => {
    event.preventDefault();

    this.hideAlert();
    await this.setStateAsync({ isLoading: true });

    try {
      let { email } = this.state
      if (email.startsWith('!')) {
        email = email.substring(1)
      }

      const success = await Auth.confirmSignUp(email, this.state.verificationCode)
	  console.log('ConfirmSignUp', success)

      const user = await Auth.signIn(email, this.state.password)
      console.log('User', user)

      console.log('set state: isVerified')
      await this.setStateAsync({ /* isAuthenticated: true, */ isLoading: false, isVerified: true });
      console.log('done - set state')

      console.log('set authenticated')
      // Don't do this: fires a non-async setState() and causes problems.!!
      await this.props.userHasAuthenticated(true)
      console.log('done - set authenticated')

      console.log('remember pwd')
      await this.props.rememberPassword(this.state.password)
      console.log('done - remembered password')

      this.props.history.push('/litdetails')
    } catch (e) {
      // alert(e.message);
      console.log("confirmSignUp()", "Error:", e.message)
      this.showAlert(e.message)

      await this.setStateAsync({ isLoading: false, isVerified: false });
    }
  }

  renderVerificationForm() {
    return (

      <form onSubmit={this.handleVerificationSubmit}>
        <PageTitleWidget intro={
        //   <React.Fragment>Sign up for a free LIT account</React.Fragment>
			<React.Fragment>
				Please check email for your code
			</React.Fragment>
        }/>
        <AlertMsg message={this.state.alertMessage} />
		<FormGroupFix controlId="email" bsSize={this.state.bsSize}
            validationState={this.validateEmail()} >
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoComplete="off"
            type="email"
            value={this.state.email}
            placeholder="Email address used to sign up for LIT"
            onChange={this.handleChange}
          />
          {/* <HelpBlock>Please provide your university email</HelpBlock> */}
        </FormGroupFix>
        <FormGroupFix controlId="verificationCode" bsSize={this.state.bsSize}>
          <ControlLabel>Verification Code</ControlLabel>
          <FormControl
            autoComplete="off"
            type="tel"
            value={this.state.verificationCode}
            onChange={this.handleChange}
          />
        </FormGroupFix>
		{/* <HelpBlock>Please check your email for the code.</HelpBlock> */}
        <LoaderButton
          id="verify"
          className="btn-school"
          bsStyle="success"
          block
          bsSize={this.state.bsSize}
          disabled={!this.validateVerificationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="VERIFY"
          loadingText="Verifying…"
        />
		<div style={{textAlign: "center"}}>
			<a href="." onClick={this.handleToggleVerification}>
				<h5>Go Back to Signup</h5>
			</a>
		</div>
      </form>
    );
  }

  //*************** REGISTRATION

  validateForm() {
    return (
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword &&
      this.state.email.length > 0 &&
      'success' === this.validateEmail()
    );
  }

  validateEmail() {
    const { email } = this.state
	if (!email) return null

	const parts = getAccountDomainFromEmail(email)
	if (0 === parts.length) return 'warning'
	if (1 === parts.length) return null

    // So if here, is 'x@y...' only two parts which is correct
	const [ account, domain ] = parts
	if (!account || !domain) // Just double check	
		return 'warning'

	const domainParts = domain.split('.')
	if (1 >= domainParts.length)
		return 'warning'

	if (isApprovedEmailAddress(email))
		return 'success'

    return 'error'
  }

  validatePassword() {
    const { password } = this.state

    // If empty, no color
    if (0 >= password.length)
      return null
    
    if (8 > password.length)
      return 'warning'

    // TODO: check for all the valid stuff too?

    return 'success'
  }

  validateConfirmPassword() {
    const { password, confirmPassword } = this.state

    // If both are empty, no color
    if (0 >= password.length && 0 >= confirmPassword.length)
      return null

    const isPasswordValid = ('success' === this.validatePassword())
    if (!isPasswordValid)
      return null

    if (isPasswordValid && 0 >= confirmPassword.length)
      return 'warning'

    if (password !== confirmPassword)
      return 'error'

    return 'success'
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.hideAlert();
    this.setState({ isLoading: true });

    try {    
      // TESTING:
      let isTesting = false

      let email = this.state.email
      if (email.startsWith('!')) {
        isTesting = true
        email = email.substring(1)
      }

      this.showAlert('Check your ' + email + ' email for a code and enter it below ')

	  // TODO Validate the email address, get the school ID, etc.

	  // Sign them up
      const newUser = await Auth.signUp({
        username: email,
        password: this.state.password,
        attributes: {
          given_name: this.state.firstName,
          family_name: this.state.lastName,
          name: isTesting ? 'Test Student' : 'Yale Student',
          website: 'yale.edu',
        }
      });
      this.setState({
        newUser,
		verify: true,
      });
    } catch (e) {
      // alert(e.message);
      console.log("signUp()", "Error:", e.message, e)
      this.showAlert(e.message)
    }

    this.setState({ isLoading: false });
  }
  
  renderForm() {
    return (
      <React.Fragment>
      <form onSubmit={this.handleSubmit}>
        <PageTitleWidget intro={
        //   <React.Fragment>Sign up for a free LIT account</React.Fragment>
			<React.Fragment>
				Sign up for a free LIT account
				<a href="." onClick={this.handleToggleVerification}>
					<h5>Or tap here with a verification code</h5>
				</a>
			</React.Fragment>
        }/>
        <AlertMsg message={this.state.alertMessage} />
        <FormGroupFix controlId="firstName" bsSize={this.state.bsSize}>
          <ControlLabel>First Name</ControlLabel>
          <FormControl
            autoComplete="off"
            type="text"
            value={this.state.firstName}
            // placeholder="Required"
            onChange={this.handleChange}
          />
          <FormControl.Feedback />
        </FormGroupFix>
        <FormGroupFix controlId="lastName" bsSize={this.state.bsSize}>
          <ControlLabel>Last Name</ControlLabel>
          <FormControl
            autoComplete="off"
            type="text"
            value={this.state.lastName}
            // placeholder="Required"
            onChange={this.handleChange}
          />
        </FormGroupFix>
        <FormGroupFix controlId="email" bsSize={this.state.bsSize}
            validationState={this.validateEmail()} >
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoComplete="off"
            type="email"
            value={this.state.email}
            placeholder="Enter your school email address"
            onChange={this.handleChange}
          />
          {/* <HelpBlock>Please provide your university email</HelpBlock> */}
        </FormGroupFix>
        {/* <FormGroupFix controlId="password" bsSize={this.state.bsSize}
            validationState={this.validatePassword()} >
          <ControlLabel>Password (not your NetID one!)</ControlLabel>
          <FormControl
            autoComplete="off"
            value={this.state.password}
            placeholder="8 min, include lower, upper, and number"
            onChange={this.handleChange}
            type="password"
          />
        </FormGroupFix>
        <FormGroupFix controlId="confirmPassword" bsSize={this.state.bsSize}
            validationState={this.validateConfirmPassword()} >
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            autoComplete="off"
            value={this.state.confirmPassword}
            // placeholder="Required"
            onChange={this.handleChange}
            type="password"
          />
        </FormGroupFix> */}
        <HelpBlock>* All fields are required</HelpBlock>
        <LoaderButton
          id="register"
          className="btn-school"
          bsStyle="success"
          block
          bsSize={this.state.bsSize}
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="SIGN UP"
          loadingText="Registering…"
        />
      </form>
      <LogoCopyrightFooter noschool noimage />
     </React.Fragment>
    );
  }

  render() {
    // if (this.state.isVerified) {
    //   console.log('send user to /details')
    //   return <Redirect push to="/details" />
    // }

    return (
      <div className="Signup">
        {this.state.verify === null
          ? this.renderForm()
          : this.renderVerificationForm()}
      </div>
    );
  }
}
