import React, { Component } from "react";
import { Auth, API } from "aws-amplify";
import "./Done.css";

import { YaleTitleWidget, AlertMsg } from "../components/ScreenWidgets";

export default class Test extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alertMessage: "",

            bsSize: "", // "small", "", "large"
        };
    }
    sampleCloudApi
    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            console.log('Done', 'componentDidMount', "isAuth", this.props.isAuthenticated)
            return
        }
        let user = await Auth.currentAuthenticatedUser();
        console.log('CDM', user)
        this.setState({ user: user })

        let apiName = 'User Profile';
        let path = '/profile';
		// eslint-disable-next-line no-unused-vars
        let myInit = { // OPTIONAL
            headers: {
                "Authorization": user.signInUserSession.idToken.jwtToken
            }, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {  // OPTIONAL
                name: 'param'
            },
        }
        // API.get(apiName, path, myInit).then(response => {
        API.get(apiName, path, {}).then(response => {
            // Add your code here
            console.log(response)
            this.setState({response: response})
        }).catch(error => {
            console.log(error.response)
        });

        API.get('sampleCloudApi', '/items').then(response => {
            console.log('sample', response)
        }).catch(error => {
            console.log('sample', error.response)
        })
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    showAlert(msg) {
        this.setState({ alertMessage: msg })
    }

    hideAlert() {
        this.setState({ alertMessage: "" })
    }

    render() {
        return (
            <div className="Screen">
                <YaleTitleWidget intro="Testing" />
                <AlertMsg message={this.state.alertMessage} />
                <div>RESPONSE:</div>
                <div>{JSON.stringify(this.state.response)}</div>
                <div>USER:</div>
                <div>{JSON.stringify(this.state.user)}</div>
            </div>
        );
    }
}
