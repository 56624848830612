import React from "react";
import { Button, Glyphicon } from "react-bootstrap";
import "./LoaderButton.css";

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) => {
  const { bsSize, ...rest } = props
  return (
    !!bsSize ? <Button
      className={`LoaderButton ${className}`}
      disabled={disabled || isLoading}
      bsSize={bsSize}
      {...rest}
    >
      {isLoading && <Glyphicon glyph="refresh" className="spinning" />}
      {!isLoading ? text : loadingText}
    </Button>

    : <Button
      className={`LoaderButton ${className}`}
      disabled={disabled || isLoading}
      {...rest}
    >
      {isLoading && <Glyphicon glyph="refresh" className="spinning" />}
      {!isLoading ? text : loadingText}
    </Button>
  )
}