import React, { Component, Fragment } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
// import { PageHeader } from "react-bootstrap";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import "./Home.css";
import { LogoCopyrightFooter } from "../components/ScreenWidgets";

export default class LITWelcome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    console.log('componentDidMount()', 'isAuth', this.props.isAuthenticated)
    if (!this.props.isAuthenticated) {
      this.setState({ isLoading: false });
      return;
    }

    // Do work here for authenticated users

    this.setState({ isLoading: false });
  }

  handleLogout = async event => {
    await Auth.signOut();

    await this.props.userHasAuthenticated(false);

    // this.props.history.push("/login");
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Welcome to LIT by Fintrinsic</h1>
        <div>
          {/* <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link> */}
          <Link to="/lit" className="btn btn-success btn-lg">
            SIGN UP
          </Link>
        </div>
        <LogoCopyrightFooter />
      </div>
    );
  }

  renderMistake() {
    return (
      <div className="lander">
        <h1>Welcome Back!</h1>
        <div>
	        <p>It looks like you're already set with an account.</p>
			<Link onClick={this.handleLogout} className="btn btn-success btn-lg">
				LOG OUT
			</Link>
		</div>
        <LogoCopyrightFooter noschool />
      </div>
    //   <div className="notes">
    //     <PageHeader>Welcome Back!</PageHeader>
    //     <div>Hmm. Sorry - didn't mean to send you here! Email "info (AT) reisupllc.com" with questions.</div>
    //   </div>
    );
  }

  render() {
    return (
      <div className="Home">
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">fintrinsic</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              {this.props.isAuthenticated
                ? <Fragment>
                    <NavItem onClick={this.handleLogout}>Logout</NavItem>
                  </Fragment>
                : <Fragment>
                    {/* <LinkContainer to="/welcome">
                      <NavItem>Welcome</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/details">
                      <NavItem>Details</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/samples">
                      <NavItem>Samples</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/done">
                      <NavItem>Done</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <NavItem>Login</NavItem>
                    </LinkContainer> */}
                  </Fragment>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {this.props.isAuthenticated ? 
          this.renderMistake()
          : this.renderLander()}
      </div>
    );
  }
}
