/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1f11440f-6cb2-48f8-8618-0f93d2ee35a5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ojejH4k1U",
    "aws_user_pools_web_client_id": "3t2ubii31me3af06op9r7c7toj",
    "oauth": {},
    "aws_mobile_analytics_app_id": "93584ceb4c7b4f36973e8a391d809b04",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_content_delivery_bucket": "usermgmt-hosting-mobilehub-2100995650",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://s3.amazonaws.com/usermgmt-hosting-mobilehub-2100995650",
    "aws_cloud_logic_custom": [
        {
            "id": "54kll371dl",
            "name": "User Profile",
            "description": "Create user profile, update profile, trigger Teachable connection",
            "endpoint": "https://54kll371dl.execute-api.us-east-1.amazonaws.com/Development",
            "region": "us-east-1",
            "paths": [
                "/profile",
                "/profile/123"
            ]
        },
        {
            "id": "gh89is6m61",
            "name": "sampleCloudApi",
            "description": "",
            "endpoint": "https://gh89is6m61.execute-api.us-east-1.amazonaws.com/Development",
            "region": "us-east-1",
            "paths": [
                "/items",
                "/items/123"
            ]
        }
    ],
    "aws_app_analytics": "disable",
    "aws_cloud_logic": "enable",
    "aws_content_delivery": "enable",
    "aws_content_delivery_cloudfront": "enable",
    "aws_content_delivery_cloudfront_domain": "djn2uv3saerfi.cloudfront.net",
    "aws_dynamodb": "enable",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "usermgmt-mobilehub-2100995650-AWSMobileTable",
            "attributes": [
                {
                    "name": "teamId",
                    "type": "S"
                },
                {
                    "name": "personId",
                    "type": "S"
                },
                {
                    "name": "email",
                    "type": "S"
                },
                {
                    "name": "personName",
                    "type": "S"
                },
                {
                    "name": "phone",
                    "type": "S"
                }
            ],
            "indexes": [
                {
                    "indexName": "personName-index",
                    "hashKey": "teamId",
                    "rangeKey": "personName"
                }
            ],
            "region": "us-east-1",
            "hashKey": "teamId",
            "rangeKey": "personId"
        },
        {
            "tableName": "usermgmt-mobilehub-2100995650-UserProfile",
            "attributes": [
                {
                    "name": "userId",
                    "type": "S"
                },
                {
                    "name": "class_year",
                    "type": "S"
                },
                {
                    "name": "creationDate",
                    "type": "N"
                },
                {
                    "name": "email",
                    "type": "S"
                },
                {
                    "name": "extra",
                    "type": "M"
                },
                {
                    "name": "family_name",
                    "type": "S"
                },
                {
                    "name": "given_name",
                    "type": "S"
                },
                {
                    "name": "school",
                    "type": "S"
                },
                {
                    "name": "school_details",
                    "type": "M"
                },
                {
                    "name": "social",
                    "type": "S"
                },
                {
                    "name": "sources",
                    "type": "L"
                },
                {
                    "name": "survey",
                    "type": "M"
                }
            ],
            "indexes": [
                {
                    "indexName": "school_idx_email_sorted",
                    "hashKey": "school",
                    "rangeKey": "email"
                },
                {
                    "indexName": "school_idx_year_sorted",
                    "hashKey": "school",
                    "rangeKey": "class_year"
                },
                {
                    "indexName": "DateSorted",
                    "hashKey": "userId",
                    "rangeKey": "creationDate"
                },
                {
                    "indexName": "email_idx",
                    "hashKey": "email"
                }
            ],
            "region": "us-east-1",
            "hashKey": "userId"
        }
    ],
    "aws_project_id": "9fe99994-e41b-4009-a69d-868d7536e638",
    "aws_project_name": "user-mgmt-2018-08-15",
    "aws_resource_bucket_name": "usermgmt-deployments-mobilehub-2100995650",
    "aws_resource_name_prefix": "usermgmt-mobilehub-2100995650",
    "aws_sign_in_enabled": "enable",
    "aws_user_pools": "enable"
};


export default awsmobile;
