import React, { Component } from "react";
import { Auth } from "aws-amplify";
import {
  HelpBlock,
  FormControl,
  ControlLabel,
  Radio,
  Checkbox,
} from "react-bootstrap";
import Select from 'react-select';
import _ from "lodash";

import { YaleTitleWidget, LogoCopyrightFooter, AlertMsg } from "../components/ScreenWidgets";
import LoaderButton from "../components/LoaderButton";
import { FormGroupFix, SafeNewTabLink, enrollNewUser, sendProfile } from '../components/Utility'
import "./Details.css";
import renderSurvey from "./Survey";
import School from '../components/School'

const Constants = {
	module: 'Details',
	courseID: 400756, // Yale
}

const SimpleRadio = (props) => { return (
  <Radio value={props.value} name="classYear" className="radios" onClick={props.onClick} >{props.value}</Radio>
)}

export default class Details extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // WARNING: if we add more non-profile state, then adjust _.omit call below.
      isLoading: false,
      isSurvey: false,
      newUser: null,
      alertMessage: "",
      bsSize: "", // "small", "", "large"

      // Profile from here down
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      internal_name: "",
      school: "",
      userId: "",

      affiliation: null,
      residentialCollege: null,
      source: null,
      classYear: null,
      social: null,

      reisup_terms: false,
      teachable_terms: false,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      console.log('Details', 'componentDidMount', "isAuth", this.props.isAuthenticated)
      return
    }

    console.log('Details', 'componentDidMount', {
      history: !!this.props.history,
      isAuth: !!this.props.isAuthenticated,
    })

    try {
      const password = this.props.getPassword()
      console.log('Details', 'check pwd', password)

      const session = await Auth.currentSession()
      console.log('Details', 'componentDidMount', 'currentSession', !!session, session)

      const payload = _.get(session, 'idToken.payload')
      if (!!password && !!session && !!payload) {
        // const { email, email_verified, family_name, given_name, name, website, sub } = payload
        const { email, family_name, given_name, name, website, sub } = payload

        this.setState({
          password: password, email: email, first_name: given_name, last_name: family_name,
          internal_name: name, school: website, userId: sub
        })
      }
    }
    catch (err) {
      console.log('Details', 'ERROR:', err)
    }
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
        this.setState(state, resolve)
    });
  }

  showAlert(msg) {
    this.setStateAsync({ alertMessage: msg })
    .then(window.scrollTo(0,100))
  }

  hideAlert() {
    this.setState({ alertMessage: "" })
  }

  handleSurveyChanges = event => {
    console.log('handleSurveyChanges', event.target.name, event.target.value)

    const survey = this.state.survey || { }
    survey[event.target.name] = event.target.value
    this.setState({ survey: survey })
  }

  handleChange = event => {
    console.log('handleChange', event.target.id, event.target.value)
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleCheckbox = event => {
    console.log('handleCheckbox', event.target.id, event.target.checked)
    this.setState({
      [event.target.id]: event.target.checked
    });
  }

  handleAffiliation = (thing, action) => {
    this.handleSelect('affiliation', thing, action)
  }

  handleDorm = (thing, action) => {
    this.handleSelect('residentialCollege', thing, action)
  }

  handleSources = (thing, action) => {
    this.handleSelect('source', thing, action)
  }

  handleSelect = (targetId, thing, action) => {
    // PEKNOTE: which select is it?

    console.log('handleSelect', targetId, thing, action)
    if (_.isArray(thing)) {
      // console.log("ERROR: should not have array object from Select action")
      const arr = _.map(thing, (e) => _.get(e, 'value'))
      this.setState({ [targetId]: arr})
    }
    else if (_.isObject(thing)) {
      if (_.get(thing, 'value')) {
        // Do something
        this.setState({ [targetId]: thing.value })
      }
      else {
        console.log("ERROR: unknown format in Select action")
      }
    }
    else if (_.isEmpty(thing)) {
      console.log("ERROR: Select should not cause empty action")
    }
    else {
      console.log("ERROR: unknown value in Select action")
    }
  }

  handleRadio = event => {
    const label = event.target.name || event.target.id
    console.log('handleRadio', label, event.target.id, event.target.name, event.target.value, event.target.checked)
    this.setState({
      [label]: event.target.value
    });
  }

  async doEnrollment() {
    try {
    //   const { email, password, first_name, last_name, internal_name, school, userId } = this.state
      const { email, password, first_name, last_name, userId } = this.state
      console.log('Enrolling user')

      const response = await enrollNewUser(Constants.courseID, email, password, `${first_name} ${last_name}`)
      let data = await response.json()
      console.log("enrollNewUser() response.json()", data)

      if (data.status === 'success') {
        this.setState({ didEnroll: true })

        const profile = _.omit(this.state, ['isLoading', 'isSurvey', 'newUser', 'alertMessage', 'bsSize'])
        const resp2 = await sendProfile(email, userId, profile)
        let data2 = await resp2.json()
        console.log('sendProfile() resp2.json()', data2)
      }
      else {
        throw new Error({success: false, message: "Enrollment failed"})
      }
    }
    catch (err) {
      console.log("Details", "doEnrollment()", '', err)
      this.setState({ didEnroll: false })
      throw new Error({success: false, message: err['message'] || err })
    }
  }

  handleSubmit = async (evt) => {
    // We don't want form to submit for us, we're going to do it here, then redirect below.
    evt.preventDefault()

    console.log('handleSubmit', 'starting...')
    await this.setStateAsync({ isLoading: true });
    
    try {
      // Do work....
      // Gather survey stuff
      console.log('handleSubmit()', 'state', this.state)

      // Enrolling will get its own things.
      await this.doEnrollment()

      // Redirect to welcome message
      this.props.history.push("/done");
    }
    catch (err) {
      console.log('Details', 'handleSubmit', 'ERROR:', err && err.message)
      this.showAlert(err['message'] || err)
      await this.setStateAsync({ isLoading: false });
    }    
  }

  handleNext = async () => {
    console.log('handleNext','Yo, here')
    console.log('state', this.state)

    // this.setState({ isSurvey: true });
    this.setStateAsync({ isSurvey: true })
    .then(() => window.scrollTo(0,0))
  }

  validateForm() {
    console.log(this.state)
    return (
      // !!this.state.affiliation > 0
      this.state.reisup_terms &&
      this.state.teachable_terms &&
      this.state.classYear &&
      this.state.residentialCollege &&
      this.state.source &&
      this.state.affiliation
    );
  }

  // TODO: can just make this render(), there's no switching on conditions? perhaps?
  renderForm() {
    // PEKNOTE: don't do autofocus! Pops keyboard on mobile!
    const affilOpts = _.map(School.affiliations, (val, idx) => {
      return { value: val, label: val }
    })
    const dormOpts = _.map(School.dorms, (val, idx) => {
      return { value: val, label: val }
    })
    const sourceOpts = _.map(School.sources, (val, idx) => {
      return { value: val, label: val }
    })
    return (
      <React.Fragment>
      <form onSubmit={this.handleSubmit}>
        {/* Show the survey */}
        { this.state.isSurvey && 
            <React.Fragment>
              <YaleTitleWidget intro="Almost there!" />
              <AlertMsg message={this.state.alertMessage} />
              {renderSurvey(this.handleSurveyChanges)}
              <LoaderButton
                bsStyle="success"
                className="btn-school"
                block
                bsSize={this.state.bsSize}
                disabled={false}
                type="submit"
                isLoading={this.state.isLoading}
                text="SUBMIT"
                loadingText="Loading..."
              />

            </React.Fragment>
        }

        {/* Show the proflie info */}
        { !this.state.isSurvey &&
        <React.Fragment>
        <YaleTitleWidget intro="Tell us more about yourself!" />
        <AlertMsg message={this.state.alertMessage} />
        <FormGroupFix controlId="email" bsSize={this.state.bsSize}>
          <ControlLabel>Full Name</ControlLabel>
          <FormControl.Static>
            {`${this.state.first_name} ${this.state.last_name}` }
          </FormControl.Static>
          <ControlLabel>Email</ControlLabel>
          <FormControl.Static>
            {this.state.email}
          </FormControl.Static>
        </FormGroupFix>
        <FormGroupFix controlId="affiliation" bsSize={this.state.bsSize}>
          <ControlLabel>Yale Affiliation: *</ControlLabel>
          <Select options={affilOpts} isSearchable={false} onChange={this.handleAffiliation} />
        </FormGroupFix>
        <FormGroupFix controlId="residentialCollege" bsSize={this.state.bsSize}>
          <ControlLabel>Residential College: *</ControlLabel>
          <Select options={dormOpts} isSearchable={false} onChange={this.handleDorm} />
        </FormGroupFix>
        <FormGroupFix controlId="classYear">
          <ControlLabel>Class Year: *</ControlLabel>
          <div>
          <SimpleRadio value="2020" onClick={this.handleRadio} />{' '}
          <SimpleRadio value="2021" onClick={this.handleRadio} />{' '}
          <SimpleRadio value="2022" onClick={this.handleRadio} />{' '}
          <SimpleRadio value="2023" onClick={this.handleRadio} />{' '}
          <SimpleRadio value="After 2023" onClick={this.handleRadio} />{' '}
          </div>
        </FormGroupFix>
        <FormGroupFix controlId="source" bsSize={this.state.bsSize}>
          <ControlLabel>How did you hear about LIT?: * (check all that apply)</ControlLabel>
          <Select isMulti={true} options={sourceOpts} isSearchable={false} onChange={this.handleSources} />
        </FormGroupFix>
        <FormGroupFix controlId="social" bsSize={this.state.bsSize}>
          <ControlLabel>What's your Instagram handle?</ControlLabel>
          {/* <InputGroup> */}
            {/* <InputGroup.Addon>@</InputGroup.Addon> */}
            <FormControl
              type="text"
              // value={this.state.social}
              placeholder="@"
              onChange={this.handleChange}
            />
          {/* </InputGroup> */}
        </FormGroupFix>
        <FormGroupFix controlId="termsConditions" bsSize={this.state.bsSize}>
          <ControlLabel>Terms and Conditions: *</ControlLabel>
          <Checkbox id="reisup_terms" onChange={this.handleCheckbox}>
            I have read and agree to the 
            &nbsp;<SafeNewTabLink href="https://learn.reisupllc.com/p/privacy">Privacy Policy</SafeNewTabLink>
            &nbsp;and
            &nbsp;<SafeNewTabLink href="https://learn.reisupllc.com/p/terms">Terms of Service</SafeNewTabLink> for LIT.
          </Checkbox>
          <Checkbox id="teachable_terms" onChange={this.handleCheckbox}>
            I also agree to Teachable's 
            &nbsp;<SafeNewTabLink href="https://teachable.com/terms-of-use">Terms of Use</SafeNewTabLink>
            &nbsp;and
            &nbsp;<SafeNewTabLink href="https://teachable.com/privacy-policy">Privacy Policy</SafeNewTabLink>
            &nbsp;(p.s. Teachable is where we host all our awesome content.)
            </Checkbox>
        </FormGroupFix>
        <HelpBlock>* fields are required</HelpBlock>
        <LoaderButton
          bsStyle="success"
          className="btn-school"
          block
          bsSize={this.state.bsSize}
          disabled={!this.validateForm()}
          // type="submit"
          isLoading={this.state.isLoading}
          text="NEXT"
          onClick={() => { 
            console.log('WTF')
            this.handleNext()}
          }
          loadingText="Loading..."
          />
          </React.Fragment>
        }
      </form>
      <LogoCopyrightFooter />
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="Details"> 
        {this.renderForm()}
      </div>
    );
  }
}
