import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const Constants = {
    module: 'useGATracker',
    UA: 'UA-74510240-1',
}

export const useGAPageView = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
            console.log('GA::initialize()')
            ReactGA.initialize(Constants.UA);
            setInitialized(true);
        } else {
            console.warn('GA not initialized, on localhost')
		}
    }, []);

    useEffect(() => {
		const loc = location.pathname + location.search
        if (initialized) {
            console.log('GA::pageview()', loc)
            ReactGA.pageview(loc);
        } else {
            console.warn('GA no pageview, on localhost', (loc));
		}
    }, [initialized, location]);

    return [initialized, initialized && ReactGA];
};

export default useGAPageView;
