import React from 'react'
import styled from 'styled-components'

import { useShortAnswerInput } from 'react-google-forms-hooks'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`


const ErrorLabel = styled.span`
  color: red;
  padding: 10px 0;
`

export default function ShortAnswerInput({ id }) {
  const { register, error } = useShortAnswerInput(id)

  return (
	<Container>
		<div>
			<input type='text' {...register()} />
		</div>
		<ErrorLabel>{error && 'This field is required'}</ErrorLabel>
	</Container>
  )
}

export function EmailInputReadOnly({ id, email }) {
	const { register, error } = useShortAnswerInput(id)
    // console.log('Email error?', error)

	return (
		<Container>
			<div>
				<input type='text' {...register()} readOnly value={email} style={{minWidth: 250 }}/>
			</div>
			<ErrorLabel>{error && 'This field is required'}</ErrorLabel>
		</Container>
	)
  }
  