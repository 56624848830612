const affiliations = [
    "Yale College (undergrad)",
    "Graduate School of Arts & Sciences",
    "School of Architecture",
    "School of Art",
    "Divinity School",
    "School of Drama",
    "School of Engineering & Applied Science",
    "School of Forestry & Environmental Studies",
    "Law School",
    "School of Management",
    "School of Medicine",
    "School of Music",
    "School of Nursing",
    "School of Public Health",
  ]
  
const dorms = [
    "Benjamin Franklin",
    "Berkeley",
    "Branford",
    "Davenport",
    "Ezra Stiles",
    "Grace Hopper",
    "Jonathan Edwards",
    "Morse",
    "Pauli Murray",
    "Pierson",
    "Saybrook",
    "Silliman",
    "Timothy Dwight",
    "Trumbull",
    "N/A - I’m a grad student",
  ]

const sources = [
  "Yale Well / Save Well newsletter and/or website",
  "Office of Career Strategy",
  "Yale Daily News",
  "Classmate",
  "Social Media (Yale accounts)",
  "FLAY / on-campus financial literacy sessions",
  "Social Media (Instagram / Facebook)",
  "Other",
]

export default {
    affiliations,
    dorms,
    sources,
}
