import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import './index.css';

// Use AWS override weirdness to do signin...
// import NewApp from './NewApp';
// ReactDOM.render(<NewApp />, document.getElementById('root'));

import AppBS from './AppBS';
import { BrowserRouter as Router } from "react-router-dom";
ReactDOM.render(<Router><AppBS /></Router>, document.getElementById('root'));

// PEKNOTE: put this in and the defs that override the UI are used automagically!
// import App from './App';
// ReactDOM.render(<App />, document.getElementById('root'));

registerServiceWorker();

