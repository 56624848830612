import React from "react";
import {
    Alert,
    Image,
  } from "react-bootstrap";
import _ from "lodash";
import "./ScreenWidgets.css";

const PageTitleWidget = (props) => {
    return (
        <div className='page-title-widget centered debug'>
            <Image className="lit-logo debug" src="/Gold_LIT_Logo_Combo.png" alt="Financial Literacy Registration" />
            {props.intro && 
                <h4 className="intro"><em style={{fontStyle: 'unset', fontFamily: 'unset'}} >{props.intro}</em></h4>
            }
        </div>
    )
}

const YaleTitleWidget = (props) => {
    return (
        <div className='page-title-widget centered debug'>
            <Image className="lit-logo debug" src="/Gold_LIT_Vector.png" alt="Financial Literacy Registration" />
            <h4><em>for students of</em></h4>
            <Image className="school-logo debug" src="/Yale_University_logo_logotype.png" alt="Yale University Logo" />
            {props.intro && 
                <h4 className="intro"><em>{props.intro}</em></h4>
                // Sample: <React.Fragment>Welcome!<br/>To access LIT please sign up:</React.Fragment>
            }
        </div>
    )
}

const LogoCopyrightFooter = (props) => {
	const { noschool = true, noimage = false} = props
    const schoolPart = (true === noschool) ? null : ( <SchoolLicense/> )
    const imagePart = (true === noimage) ? null : ( <Image className="logo" alt="Fintrinsic Logo" src="/FintrinsicLogo.png" /> )

    return (
        <React.Fragment>
            <div className="copyright-logo-combo">
                { imagePart }
                <div className="copyright"><p>&copy; 2023 Fintrinsic LLC. All rights reserved.</p></div>
            </div>
            { schoolPart }
        </React.Fragment>
    )
}

const SchoolLicense = (props) => {
    return (
        <div className="copyright">
            <em>
            The “Yale University” logo is owned and licensed by Yale University. All rights reserved. Fintrinsic is not sponsored by nor affiliated with Yale University, and no statements or information provided herein was reviewed or approved by the University.
            </em>
        </div>
    )
}

const AlertMsg = (props) => {
    return (
        _.get(props.message, 'length', 0) > 0 && <Alert style={{textAlign: 'center'}} bsStyle="warning">{props.message}</Alert>
    )
}

export {
    PageTitleWidget, YaleTitleWidget, LogoCopyrightFooter, SchoolLicense, AlertMsg
}
