import React from 'react'
import styled from 'styled-components'

import { useLinearInput } from 'react-google-forms-hooks'

import C from './common'
import './LinearGrid.css'

const Container = styled.div`
	display: flex;
	align-items: center;

	& * {
	margin: 0 10px;
	}
	margin-bottom 10px;
`

const ErrorLabel = styled.span`
	color: red;
`

const Row = styled.div`
	display: flex;
	flex-direction: row
`

const Option = styled.label`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0
`
const OptionLabel = styled.label`
	font-size: ${C.questionTextFontSize};
	margin: 0 1.5vw;
`

const Legend = styled.div`
	text-align: center;
	font-size: ${C.questionTextFontSize};
	word-break: break-word;
	max-width: 30%;
`
// max-width: 30%;
// min-widtH: 30%;

export default function LinearGridInput({ id }) {
  const { options, legend, error } = useLinearInput(id)

  return (
    <React.Fragment>
      <Container>
        <Legend>{legend.labelFirst}</Legend>
		<Row>
			{options.map((o) => {
				//   return <input key={o.id} type='radio' {...o.registerOption()} />
				// return (<div key={o.id} className="Option"><span>{o.label}</span><input key={o.id} type='radio' {...o.registerOption()} /></div>)
				return (<Option key={o.id}>
					<OptionLabel>{o.label}</OptionLabel>
					<input key={o.id} type='radio' {...o.registerOption()} />
				</Option>)
			})}
		</Row>
        <Legend>{legend.labelLast}</Legend>
      </Container>
      <ErrorLabel>{error && 'This field is required'}</ErrorLabel>
    </React.Fragment>
  )
}
