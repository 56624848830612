import React, { Component } from "react";
import "./Done.css";

import { YaleTitleWidget, AlertMsg } from "../components/ScreenWidgets";

export default class Details extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: "",

      bsSize: "", // "small", "", "large"
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      console.log('Done', 'componentDidMount', "isAuth", this.props.isAuthenticated)
      return
    }
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
        this.setState(state, resolve)
    });
  }

  showAlert(msg) {
    this.setState({ alertMessage: msg })
  }

  hideAlert() {
    this.setState({ alertMessage: "" })
  }

  render() {
    return (
      <div className="Screen"> 
        <YaleTitleWidget intro="Thanks! Your official enrollment email will arrive shortly. Boola Boola!" />
        <AlertMsg message={this.state.alertMessage} />
        <div>If you don’t receive an email, please reach out to lit@gofintrinsic.com for help.</div>
      </div>
    );
  }
}
