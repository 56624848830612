// From: https://github.com/francisconeves97/react-google-forms-hooks/tree/master/example

import React from 'react'
import styled from 'styled-components'
import Linkify from 'react-linkify'

import { GoogleFormProvider, useGoogleForm } from 'react-google-forms-hooks'

import C from './components/common'

// import form from './scripts/form.json'

import CheckboxInput from './components/CheckboxInput'
import RadioInput from './components/RadioInput'
import { EmailInputReadOnly } from './components/ShortAnswerInput'
import LongAnswerInput from './components/LongAnswerInput'
import RadioGridInput from './components/RadioGridInput'
import CheckboxGridInput from './components/CheckboxGridInput'
import DropdownInput from './components/DropdownInput'
import LinearGrid from './components/LinearGrid'

// CUSTOM FORM STUFF
import formReisUP from '../../../form-reisup-singlepage.json'
// import formReisUP from '../../../form-reisup.json'
// import formTest from '../../../form-test.json'
// import formShort from '../../../form-short.json'

const Constants = {
	module: 'AppExample',
}

// Logging
const log = (...args) => console.log(Constants.module, ...args)
// eslint disable-next-line
const error = (...args) => console.error(Constants.module, ...args)
// eslint disable-next-line
const mute = (...args) => null

function getEmailIdx() {
	const fieldIdx = formReisUP.fields.findIndex((val) => val.type === 'SHORT_ANSWER' && -1 !== val.label.search('email address'))
	return fieldIdx
}

function getEmailID() {
	const idx = getEmailIdx()
	const {fieldsOrder} = formReisUP
	if (-1 !== idx && fieldsOrder) {
		const key = Object.keys(fieldsOrder).find(key => fieldsOrder[key] === idx)
		return key
	}
	return null
}

function emailResetRequired() {
	const idx = getEmailIdx()
	if (-1 !== idx)
		formReisUP.fields[idx].required = false
}

emailResetRequired()
log('Email Idx', getEmailIdx())
log('Email ID', getEmailID())

const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
  padding: 50px 0;
`

// PEKNOTE question insides
const QuestionContainer = styled.div`
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 4px;
  border: 1px solid rgb(218, 220, 224);
  background-color: #fff;
`

const QuestionLabel = styled.h4`
  font-size: ${C.questionTitleFontSize};
  margin-bottom: 10px;
  /* background-color: rgb(62, 162, 168);*/
  padding: 4px;
`

const QuestionBody = styled.div`
  padding-left: 4px;
  padding-right: 4px;
`

const SectionContainer = styled.div`
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 4px;
  background-color: #8DD9BF;
  color: #495A58;
//   background-color: rgb(62, 162, 168);
  ;
`

const Section = styled.h4`
  font-size: ${C.questionTitleFontSize};
  font-weight: bold;
  margin-bottom: 10px;
  padding: 4px;
`

const SectionH3 = styled.h3`
  /* font-size: ${C.questionTitleFontSize * 1.5}; */
  font-weight: bold;
  margin-bottom: 10px;
  padding: 4px;
`


// TYPES (decoratedHref: string, decoratedText: string, key: number): React.Node
const targetedDecorator = (decoratedHref, decoratedText, key) => {
	return (
		<a href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer">
			{decoratedText}
		</a>
	)
}

const Questions = ({formJson, email}) => {
  return (
    <div>
      {formJson.fields.map((field) => {
        const { id, label, required, type } = field

        let questionInput = null
        switch (type) {
          case 'CHECKBOX':
            questionInput = <CheckboxInput id={id} />
            break
          case 'RADIO':
            questionInput = <RadioInput id={id} />
            break
          case 'SHORT_ANSWER': // WARNING: kludge for email
            questionInput = <EmailInputReadOnly id={id} email={email} />
            break
          case 'LONG_ANSWER':
            questionInput = <LongAnswerInput id={id} />
            break
          case 'RADIO_GRID':
            questionInput = <RadioGridInput id={id} />
            break
          case 'CHECKBOX_GRID':
            questionInput = <CheckboxGridInput id={id} />
            break
          case 'DROPDOWN':
            questionInput = <DropdownInput id={id} />
            break
          case 'LINEAR':
            questionInput = <LinearGrid id={id} />
            break
		  default:
			break;
        }

		// Spit out a section
		if (label && !id && !type) {
			return (
				<SectionContainer key={`section-${1000 * Math.random()}`}>
					<Linkify componentDecorator={targetedDecorator}>
		            	<Section>{label}</Section>
					</Linkify>
				</SectionContainer>
			)
		}

        if (!questionInput) {
          return null
        }

		const isRequired = required && (<span style={{color: 'red'}}>*</span>)

        return (
          <QuestionContainer key={id}>
			<Linkify componentDecorator={targetedDecorator}>
            	<QuestionLabel>{label} {isRequired}</QuestionLabel>
			</Linkify>
            	<QuestionBody>{questionInput}</QuestionBody>
          </QuestionContainer>
        )
      })}
    </div>
  )
}

const App = ({email, onSubmit, onError}) => {
  const form = formReisUP
  const methods = useGoogleForm({ form })

  const internalOnSubmit = async (data, evt) => {
    mute('internalOnSubmit()', data)

	// TODO WTF? Kludge for email
	if (email) {
		const emailID = getEmailID()
		data[emailID] = email
	}
	
	// Submit the data to Google forms
	try {
		methods.submitToGoogleForms(data)
	} catch(err) {
		error('submitToGoogleForms()', err)
	}

	// Let parent do more if needed (enrollment)
	onSubmit && onSubmit(data)
  }

  const internalOnError = async (data, evt) => {
    log('internalOnError()', data)
	onError && onError(data)
  }

  return (
    <GoogleFormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(internalOnSubmit, internalOnError)}>
	    <SectionContainer key={`section-${1000 * Math.random()}`}>
			<SectionH3>LIT Pre-Assessment</SectionH3>
			<QuestionBody style={{borderRadius: 4, backgroundColor: '#fff'}}>We're so excited to be part of your fin lit journey. 
				Please fill out this assessment so we can establish a baseline 
				for your money mindset and behaviors, and measure your progress over time!
			</QuestionBody>
		</SectionContainer>
        <Questions formJson={form} email={email} />
        <button type='submit' className="btn btn-success">Submit</button>
      </Form>
    </GoogleFormProvider>
  )
}

export default App
